export const images = [
    {
      url: './images/1.png'
    },
    {
      url: './images/2.png'
    },
    {
      url: './images/3.png'
    },
    {
      url: './images/4.png'
    },
    {
      url: './images/5.png'
    },
    {
      url: './images/6.png'
    },
    {
      url: './images/7.png'
    },
    {
      url: './images/8.png'
    },
    {
      url: './images/9.png'
    },
    {
      url: './images/10.png'
    },
    {
      url: './images/11.png'
    },
    {
      url: './images/12.png'
    },
    {
      url: './images/13.png'
    },
    {
      url: './images/14.png'
    },
    {
      url: './images/15.png'
    },
    {
      url: './images/16.png'
    },
    {
      url: './images/17.png'
    },
    {
      url: './images/18.png'
    },
    {
      url: './images/19.png'
    },
    {
      url: './images/20.png'
    },
    {
      url: './images/21.png'
    },
    {
      url: './images/22.png'
    },
    {
      url: './images/23.png'
    },
    {
      url: './images/24.png'
    },
    {
      url: './images/25.png'
    },
    {
      url: './images/26.png'
    },
    {
      url: './images/27.png'
    },
    {
      url: './images/28.png'
    },
    {
      url: './images/29.png'
    },
    {
      url: './images/30.png'
    },
    {
      url: './images/31.png'
    },
    {
      url: './images/32.png'
    },
    {
      url: './images/33.png'
    },
    {
      url: './images/34.png'
    },
    {
      url: './images/35.png'
    },
    {
      url: './images/36.png'
    },
  ]