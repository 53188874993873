import Vimeo from '@u-wave/react-vimeo';

const SuccessModal: React.FC = () => {
    return (
        <div className="modal-wrapper">
            <div className="overlay"></div>
            <div className="modal-content">
                <Vimeo
                    video="https://vimeo.com/891101333/4a5e97a14d?share=copy"
                    autoplay
                    className='video-box'
                    controls={false}
                />
            </div>
        </div>
    )
}

export default SuccessModal