
interface Props {
    btnHandler: () => void;
    closeHandler: () => void;
}
const FailModal: React.FC<Props> = ({btnHandler, closeHandler}) => {
    return (
        <div className="modal-wrapper">
            <div className="overlay"></div>
            <div className="modal-content">
                {/* <div className="close-btn" onClick={closeHandler}>
                    X
                </div> */}
                <div className="modal-txt">
                Sorry, your time ran out 
                <br />
                Please try again.
                </div>
                <div className="modal-btn" onClick={btnHandler}>
                Try again
                </div>
            </div>
        </div>
    )
}

export default FailModal