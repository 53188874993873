import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { useTimer } from 'react-timer-hook';
import { images } from './constants';
import FailModal from "./FailModal";
import SuccessModal from "./SuccessModal";
import './App.css';
// @ts-ignore
import clickSound from './click.mp3'
// @ts-ignore
import songSound from './song.mp3'

const time = new Date();
  time.setSeconds(time.getSeconds() + 360);
const App = () => {

  const [ids, setIds] = useState<string[]>([])
  const [isFirst, setFirst] = useState<boolean>(false)
  const [showHint, setShowHint] = useState<boolean>(false)
  const [modalSuccesShown, setModalSuccesShown] = useState<boolean>(false)
  const [modalFailShown, setModalFailShown] = useState<boolean>(false)
  const [isPlay, setIsPlay] = useState<boolean>(false)
  const [playCount, setPlayCount] = useState<number>(0)

  const audioBox = useRef()
  const songBox = useRef()


  const resolved = document.querySelectorAll('#resolved')
  useEffect(() => {
    if (images.length === resolved.length) {
      setModalSuccesShown(true)
      pause()
      // @ts-ignore
      songBox.current?.pause()
    }
    if (resolved.length > 0 && audioBox.current && playCount !== resolved.length ) {
      setPlayCount(resolved.length)      
        // @ts-ignore
        audioBox.current?.play()
      setTimeout(() => {
        // @ts-ignore
        audioBox.current?.pause()
      }, 1000);
    }
  }, [resolved, playCount])
  // useEffect(() => {
  //   if (images.length === ids.length) {
  //     setModalSuccesShown(true)
  //     pause()
  //   }
  // }, [ids])


  const {
    seconds,
    minutes,
    start,
    pause
  } = useTimer({ expiryTimestamp: time, onExpire: () => setModalFailShown(true), autoStart: false });
  

  const handleMove = (idx: number, x: number, y: number) => {
    const tolerance = 70;
    const toleranceX = 70;

    if (!isFirst) {
      setFirst(true)
      start()
    }

    if (!isPlay && songBox?.current) {
      setIsPlay(true)
      // @ts-ignore
      songBox?.current?.play()
      // @ts-ignore
      songBox.current.volume = 0.1
    }


    if (idx === 5) {
      const el = document.querySelector(`.puzzle-${idx}`)
      const col = document.querySelector(`.colum6`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 25) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum31`)
      
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 0) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum36`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 1) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum1`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 30) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum12`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 15) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum18`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 20) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum24`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 34) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum30`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 29) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum35`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 28) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum34`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 27) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum33`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 26) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum32`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 21) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum25`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 16) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum19`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 11) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum13`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 6) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum7`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 2) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum2`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 3) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum3`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 31) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum4`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 4) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum5`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 33) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum26`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 17) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum20`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 12) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum14`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }


    if (idx === 7) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum8`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 8) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum9`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 9) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum10`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 10) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum11`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 35) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum17`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 14) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum16`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 13) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum15`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 32) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum23`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 19) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum22`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 18) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum21`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 24) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum29`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 23) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum28`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }

    if (idx === 22) {
      const el = document.querySelector(`.puzzle-${idx}`)      
      const col = document.querySelector(`.colum27`)
      if (el && col && Math.abs(x - col?.getBoundingClientRect().x) <= toleranceX && Math.abs(y - col?.getBoundingClientRect().bottom) <= tolerance) {
        el.setAttribute("id", "resolved")
        if (!ids.includes(idx.toString())) {
          setIds([...ids, idx.toString()])
        }
      }
    }
  }

  return (
    <div className="main-wrap">
      {/* @ts-ignore */}
      <audio src={clickSound} ref={audioBox}></audio>
      {/* @ts-ignore */}
      <audio src={songSound} ref={songBox} onEnded={() => {
        // @ts-ignore
        songBox.current.play();
      }}></audio>
      <div className="title">
      Holiday fun! 
      <br />
      Solve the puzzle.
      </div>
      <img src="./images/img1.svg" alt="" className="bg1" />
      <img src="./images/img2.svg" alt="" className="bg2" />
      <div className="puzzle-wrapper">
        <div className={showHint ? "puzle-place-wrapper shown" : "puzle-place-wrapper"}>
          <div className="txt">Click and drag the pieces into place, put the puzzle 
          <br />
          together in less then 6 minutes and you win!</div>
          <div className="timer">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
          <div className={showHint ? "puzzle-place shown" : "puzzle-place"}>
            {showHint && (
              <img src="./images/hint.jpg" alt="" className='hint-img' />
            )}
            <div className="rows">
              <div className="colimns colum1"></div>
              <div className="colimns colum2"></div>
              <div className="colimns colum3"></div>
              <div className="colimns colum4"></div>
              <div className="colimns colum5"></div>
              <div className="colimns colum6"></div>
            </div>
            <div className="rows">
              <div className="colimns colum7"></div>
              <div className="colimns colum8"></div>
              <div className="colimns colum9"></div>
              <div className="colimns colum10"></div>
              <div className="colimns colum11"></div>
              <div className="colimns colum12"></div>
            </div>
            <div className="rows">
              <div className="colimns colum13"></div>
              <div className="colimns colum14"></div>
              <div className="colimns colum15"></div>
              <div className="colimns colum16"></div>
              <div className="colimns colum17"></div>
              <div className="colimns colum18"></div>
            </div>
            <div className="rows">
              <div className="colimns colum19"></div>
              <div className="colimns colum20"></div>
              <div className="colimns colum21"></div>
              <div className="colimns colum22"></div>
              <div className="colimns colum23"></div>
              <div className="colimns colum24"></div>
            </div>
            <div className="rows">
              <div className="colimns colum25"></div>
              <div className="colimns colum26"></div>
              <div className="colimns colum27"></div>
              <div className="colimns colum28"></div>
              <div className="colimns colum29"></div>
              <div className="colimns colum30"></div>
            </div>
            <div className="rows">
              <div className="colimns colum31"></div>
              <div className="colimns colum32"></div>
              <div className="colimns colum33"></div>
              <div className="colimns colum34"></div>
              <div className="colimns colum35"></div>
              <div className="colimns colum36"></div>
            </div>
          </div>
        </div>
        <div className="hint-btn" onClick={(): void => {
            setShowHint(!showHint)
          }}>hint</div>
        {images.map((el, idx) => (
            <Draggable 
              key={el.url} 
              defaultPosition={{x: Math.floor(Math.random() * 1200), y: -Math.floor(Math.random() * 500)}} 
              onStop={(e: any) => {
                // handleMove(idx, e.screenX, e.screenY)
                handleMove(idx, e.clientX, e.clientY)
              }} 
            >
              <div className={`puzzle-element puzzle-${idx}`}>
                <img draggable={false} src={el.url} className={`img-${idx}`} alt="" />
              </div>
            </Draggable>
        ))}
      </div>
      {modalFailShown && <FailModal closeHandler={() => setModalFailShown(false)} btnHandler={() => {
        window.location.reload()
      }} />}
      {modalSuccesShown && <SuccessModal />}
    </div>
  );
}

export default App;
